/**
 *	Theme typography
 *
 *	This file contains @font-face declarations.
 */


$version: '1';

/**
 *	Roboto
 */

// Thin 100
@font-face {
	font-family: 'Roboto';
	src: url('#{$theme-path}/fonts/roboto/roboto-thin.woff?v=#{$version}') format('woff'),
		 url('#{$theme-path}/fonts/roboto/roboto-thin.ttf?v=#{$version}') format('truetype');
	font-weight: 100;
	font-style: normal;
}

// Light 300
@font-face {
	font-family: 'Roboto';
	src: url('#{$theme-path}/fonts/roboto/roboto-light.woff?v=#{$version}') format('woff'),
		 url('#{$theme-path}/fonts/roboto/roboto-light.ttf?v=#{$version}') format('truetype');
	font-weight: 300;
	font-style: normal;
}

// Normal - regular 400
@font-face {
	font-family: 'Roboto';
	src: url('#{$theme-path}/fonts/roboto/roboto-regular.woff?v=#{$version}') format('woff'),
		 url('#{$theme-path}/fonts/roboto/roboto-regular.ttf?v=#{$version}') format('truetype');
	font-weight: 400;
	font-style: normal;
}

// Medium 500
@font-face {
	font-family: 'Roboto';
	src: url('#{$theme-path}/fonts/roboto/roboto-medium.woff?v=#{$version}') format('woff'),
		 url('#{$theme-path}/fonts/roboto/roboto-medium.ttf?v=#{$version}') format('truetype');
	font-weight: 500;
	font-style: normal;
}

// Bold 800
@font-face {
	font-family: 'Roboto';
	src: url('#{$theme-path}/fonts/roboto/roboto-bold.woff?v=#{$version}') format('woff'),
		 url('#{$theme-path}/fonts/roboto/roboto-bold.ttf?v=#{$version}') format('truetype');
	font-weight: 800;
	font-style: normal;
}
