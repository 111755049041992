/**
 *	Front ALL
 *
 *	This file includes and describes all used CSS.
 */


/******************************v
*  1. #META, #BASE
******************************/

@import 'base/_variables';                                               // Variables
@import 'vendor/bootstrap/bootstrap';                                    // Bootstrap-4.0.0-beta.2 (http://getbootstrap.com)
@import 'base/_mixins';                                                  // Mixins
@import 'base/_functions';                                               // Support functions
//@import 'base/_animations';                                            // Animations
@import 'base/_icons';                                                   // Icons
@import 'base/_typography';                                              // Typography
@import 'base/_master';                                                  // Master styles, atoms, content


/******************************
*  2. #LAYOUT COMPONENTS
******************************/

@import 'layout/_layout';                                                // Layout - wrapper
@import 'layout/_header';                                                // Header
@import 'layout/_header-addons';                                         // Header addons
@import 'layout/_content';                                               // Content
@import 'layout/_navigation';                                            // Navigation
@import 'layout/_guide';                                                 // Guide
@import 'layout/_footer';                                                // Footer


/******************************
*  3. #UI COMPONENTS
******************************/

@import 'components/_box';
@import 'components/_buttons';
@import 'components/_breadcrumbs';
@import 'components/_datepicker';
@import 'components/_rss-logo';
@import 'components/_forms';
@import 'components/_forms-drop-area';
@import 'components/_tabs';
@import 'components/_paginator';
@import 'components/_accordion';
@import 'components/_modal';
@import 'components/_data-box';
@import 'components/_district-selection';
@import 'components/_info-message';

@import 'components/_navigation';
@import 'components/_form-navigation';
@import 'components/_data-grid';
@import 'components/_popup';
@import 'components/_chat';



// Spinners

// z _variables.css
$spinner-width:         2rem !default;
$spinner-height:        $spinner-width !default;
$spinner-border-width:  .25em !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;

@import 'components/_spinners';




/******************************
*  4. #UI MODULES
******************************/

/*
*/

/******************************
*  5. #COMMON SUPPORTS
******************************/

@import 'common/_js-no';                                                 // No JS common
@import 'common/_debug';                                                 // Debug mixins/conditions
@import 'common/_mp';                                                    // MP commons