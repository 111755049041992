/**
 *	Guide
 */


.guide {
	position: relative;
	margin-top: -$guide-negative-offset-sp-m;
	overflow: hidden;
	padding: ( $spacer / 3 );
	@include border-radius($border-radius);

	@include gradient-directional($theme-blue-light, $theme-blue, 140deg);
	@include box-shadow($guide-shadow);

	// Media queries
	@include media-breakpoint-up(lg) {
		padding: ( $spacer / 1.5 ) ( $spacer * .5 );
	}

	&--hp {
		margin-top: -$guide-negative-offset-hp-m;
		margin-left: -( $spacer / 3 );
		margin-right: -( $spacer / 3 );

		// Media queries
		@include media-breakpoint-up(lg) {
			margin-top: -$guide-negative-offset-hp-d;
			margin-left: -( $spacer / 2 );
			margin-right: -( $spacer / 2 );
		}
	}

	&--no-right-radius {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&__title {
		@include font-size(40);
		letter-spacing: normal;
	}

	.box__title {
		a.inverse {
			&,
			&:visited {
				color: $white;
				text-decoration: none;
			}

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	h1,
	h2 {
		color: $theme-white;
	}

	h1 {
		padding-top: $spacer / 1.5;
	}

	&__link-holder {
		position: relative;
		padding-bottom: $spacer;
	}

	&__link {
		&,
		&:visited {
			display: block;
			padding-top: $spacer / 5;
			color: $theme-white;
			line-height: 1.5;
			@include font-size(20);
			text-decoration: none;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background: rgba($theme-white, 0.4);
			}
		}

		&:focus,
		&:hover {
			text-decoration: underline;
			color: $theme-white;

			&::after {
				background: $theme-white;
			}
		}

		&:active,
		&.active {
			text-decoration: none;

			.guide__link--text {
				background: $theme-white;
				color: $theme-blue-light;
				text-decoration: none;
			}

			&::after {
				background: $theme-white;
			}
		}
	}

	&__icon {
		position: absolute;
		top: 20px;
		right: -25%;
		color: $white;
		opacity: 0.1;
		@include font-size(800);
	}

	&__more {
		text-align: center;
		padding-top: $spacer / 1.5;

		// Media queries
		@include media-breakpoint-up(lg) {
			text-align: left;
			padding-top: 0;
			margin-top: -$spacer / 1.2;
		}
	}

	&__back-step {
		height: 35px;
		margin-top: $spacer / 3 ;
		padding-bottom: $spacer * .275;
		border-bottom: 1px solid $theme-white;

		// Media queries
		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}

		a {
			&,
			&:visited {
				position: relative;
				padding-left: $spacer / 3;
				@include font-size(12);
				color: $white;
				text-decoration: none;
			}

			&:empty {
				display: none;
			}

			&:focus,
			&:hover {
				text-decoration: underline;
			}

			&:active {
				text-decoration: none;
			}

			&::before {
				content: "\e911";
				position: absolute;
				top: 4px;
				left: 0;
				@include icon-mix();
				@include font-size(10);
			}
		}
	}

	&--mobile {
		margin: -( $spacer $spacer / 6 ) 0;

		h2 {
			margin: $spacer/2 0;

			//Media queries
			@include media-breakpoint-up(lg) {
				margin: $spacer/2 0 0;
			}
		}
	}

	&--white {
		padding-top: 0;
		background-image: none;
		background-color: $white;

		h2 {
			color: $theme-blue;
		}
	}
}
