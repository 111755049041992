.chat {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.chat--private {
	.msg {
		float: left;
		width: auto;
	}

	.msg--own {
		float: right;
	}

	@include media-breakpoint-up(lg) {
		.msg {
			max-width: 85%;
		}
	}
}


.msg {
	display: flex;
	align-items: flex-start;
	margin-bottom: 30px;
	width: 100%;

	&__avatar {
		flex: 0 0 60px;
		margin-right: 20px;
		width: 60px;
		height: 60px;
		font-size: 22px;
		font-weight: 500;
		line-height: 60px;
		text-align: center;
		color: white;
		background-color: $theme-blue;
		border-radius: 50%;

		&--blue 				{ background-color: $theme-blue; }
		&--lightblue 		{ background-color: #3498db; }
		&--red 					{ background-color: #e74c3c; }
		&--green 				{ background-color: #27ae60; }
		&--orange 			{ background-color: #e67e22; }
		&--purple 			{ background-color: #8e44ad; }
		&--black 				{ background-color: #2c3e50; }
		&--yellow 				{ background-color: #f1c40f; }
	}

	&__wrapper {
		flex-grow: 1;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
	}

	&__content {
		padding: 15px;
		font-size: 14px;
		line-height: 1.5;
		letter-spacing: normal;
		color: #3b3b3b;
		background-color: white;
	}

	&__header {
		margin-bottom: 10px;
	}

	&__user {
		font-weight: 500;
		margin-right: 20px;
	}


	&__responses {
		padding: 15px;
		border-top: 1px solid #d4d4d4;
		background-color: #f5f9ff;

		.msg {
			margin-bottom: 0;
		}

		.msg + .msg {
			margin-top: 25px;
		}

		.msg__avatar {
			flex: 0 0 45px;
			width: 45px;
			height: 45px;
			font-size: 18px;
			line-height: 45px;
		}

		.msg__content {
			flex-grow: 1;
			background: transparent;
			padding: 0;
		}
	}



	&--own {
		text-align: right;

		.msg__content {
			text-align: right;
			background-color: #f5f9ff;
		}

	}
}