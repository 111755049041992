body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body__wrapper-in.fullbgimg {
    background: url('/images/mmr-pozadi.png') fixed center bottom no-repeat;
}

.body__wrapper.loginpage {
    background: url('/images/login-bg-bod.jpg') center center no-repeat;
    background-size: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  fixni header */
.top-header {
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.content {
    padding-top: 120px;
}

/* fixni navigace formulare */
.form-nav {
    position: fixed;
    max-width: 300px;
    top: 180px;
}

.form-nav__item--invalid::after {
    background-color: #e04857 !important;
    background-image: url('/images/icons/close-white.svg');
    background-size: 12px 12px;
    background-position: 4px 4px;
    background-repeat: no-repeat;
}

/* vynutit zelene pozadi i pri active  */
.form-nav__item--valid::after {
    background-color: #009B49 !important;
}

.react-contexify {
    padding: 0 !important;
}

.react-contexify__item__content {
    padding: 0 !important;
    display: block !important;
}


input.with-tooltip {
    padding: 1.1rem calc(1.1rem + 30px) 1.1rem 1.1rem;
}

.input-tooltip {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 30px;
    cursor: pointer;
    content: url('/images/icons/info-circle-solid.svg');
}
.loginpage .input-tooltip {
    content: url('/images/icons/info-circle-solid_black.svg');
}

.data-grid__sort {
    position: absolute;
    right: 0;
    top: 18px;
}


.paginator__item {
    outline: none !important;
}

/* formular */
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    color: black !important;
    background-color: rgba(255, 255, 255, 0.6) !important;
    opacity: 0.9 !important;
}

.custom-control .custom-control-input[disabled] ~ label, .custom-control .custom-control-input[readonly] ~ label {
    opacity: 1 !important;
    color: black !important;
}

form .control-label, form .col-form-label {
    font-size: 1.4rem;
}

/* popup */
.popup--open .popup__window {
    position: fixed;
}

/* toast */
.react-toast-notifications__container {
    z-index: 2000 !important;
    top: 120px !important;
    overflow-y: hidden !important;
}

.react-toast-notifications__container {
    overflow-wrap: anywhere;
}

/*.react-toast-notifications__toast__icon{*/
/*    display: none!important;*/
/*}*/

@media (min-width: 576px) {
    h1, .h1 {
        font-size: 50px !important;
    }
}


/*
vyska menu formulare
 */
@media (max-height: 750px) {
    .form-nav__menu li {
        font-size: 12px;
    }

    .form-nav__menu li {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .form-nav__title::after, .form-nav__item::after, .form-nav__end::after {
        top: 10px !important;
    }

    .form-nav__item--active::after {
        top: 5px !important;
        z-index: 2 !important;
    }

    .form-nav {
        position: sticky;
        top: 90px;
    }
}

.form-nav__menu li {
    padding-left: 10px !important;
}

/*
modal
 */
.popup__window {
    z-index: 999;
}

.popu__fullHeight {
    transform: translate(-50%, 0) !important;
    top: 125px !important;
    bottom: 20px !important;
    overflow: auto !important;
}

.popup__header {
    font-size: 20px !important;
}


.pointer {
    cursor: pointer !important;
}

.nopointer {
    cursor: default !important;
}

/*
table
 */

.data-grid td {
    background: inherit;
}

.data-grid tbody tr {
    background: #f5f5f5;
}

.data-grid tbody tr:nth-child(even) {
    background: #fbfbfb;
}

.data-grid tbody tr:hover, .data-grid tbody tr:hover td {
    background: white !important;
}

.data-grid thead tr:hover {
    background: inherit;
}

.react-contexify {
    z-index: 100;
}

.form-group .control-label {
    cursor: default;
}

/*  dete input */
input[type=date]::-webkit-clear-button {
    display: none !important;
    -webkit-appearance: none !important;

}

textarea.form-control[disabled], textarea.form-control[readonly], textarea.form-control {
    cursor: default !important;
    pointer-events: auto !important;
}

.form-group textarea {
    resize: vertical !important;
    min-height: 150px !important;
}

/* login */
.login-title {
    color: black;
    font-weight: normal;
    position: relative;
    margin-bottom: 50px;
    max-width: 80%;
}

@media (min-height: 950px) {
    .login-title {
        margin-bottom: 200px;

    }
}

.login-title::before {
    position: absolute;
    display: block;
    content: "";
    width: 380px;
    height: 250px;
    background: url('/images/nadpis_logo_mapa2.png') center center no-repeat;
    background-size: contain;
    left: -100px;
    top: -110px;
}

.login-title::after {
    position: absolute;
    display: block;
    content: "";
    width: 85px;
    height: 85px;
    background: url('/images/nadpis_logo_bod2.png') center center no-repeat;
    background-size: contain;
    left: 100%;
    bottom: 0;
}

.loginbtn {
    width: 200px;
    background-color: #f6d107 !important;
    border-color: #f6d107 !important;
    color: black !important;
}

.btn.btnlogininfo {
    background: white !important;
}

.loginalert {
    position: relative;
    max-width: 390px;
    padding-left: 50px;
    color: black;
}

.loginalert::before {
    left: 0px;
    position: absolute;
    display: block;
    content: "";
    width: 40px;
    height: 40px;
    background: url('/images/banner_stop.png') center center no-repeat;
    background-size: contain;
}

/* tooltip */
.tooltip .tooltip-inner {
    background: #2362a2;
    font-size: 16px;
    padding: 5px;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #2362a2 !important;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: #2362a2 !important;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: #2362a2 !important;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: #2362a2 !important;
}



.loginpage .tooltip .tooltip-inner {
    background: #f6d107;

    font-size: 16px;
    padding: 5px;
}

.loginpage .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #f6d107 !important;
}

.loginpage .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: #f6d107 !important;
}

.loginpage .bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: #f6d107 !important;
}

.loginpage .bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: #f6d107 !important;
}




.tooltip-inner {
    max-width: 250px !important;
}

.buttonintertooltip {
    max-width: 500px !important;
    text-align: left !important;
    padding: 25px !important;
    font-size: 14px !important;
    background: #f6d107 !important;
    color: black !important;
}


/* sticky head  normal table */

.table-with-sticky-head {
    overflow-x: initial !important;
}

.sticky-normal-head-cell {
    position: sticky;
    z-index: 100;
    top: 80px;

}

@media (min-width: 1260px) {
    .sticky-normal-head-cell {
        top: 110px;
    }
}

.shadowbox {
    /*-webkit-box-shadow: 0px 6px 27px 3px rgba(0, 0, 0, 0.2);*/
    /*-moz-box-shadow: 0px 6px 27px 3px rgba(0, 0, 0, 0.2);*/
    /*box-shadow: 0px 6px 27px 3px rgba(0, 0, 0, 0.2);*/
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.no-inline-tb-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}