/*!
 * Datepicker for Bootstrap v1.4.1 (https://github.com/eternicode/bootstrap-datepicker)
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
  padding: 4px;
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker.datepicker-rtl {
  direction: rtl;
  table tr td span {
    float: right;
  }
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  &:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
  }
  &.datepicker-orient-left {
    &:before {
      left: 6px;
    }
    &:after {
      left: 7px;
    }
  }
  &.datepicker-orient-right {
    &:before {
      right: 6px;
    }
    &:after {
      right: 7px;
    }
  }
  &.datepicker-orient-top {
    &:before {
      top: -7px;
    }
    &:after {
      top: -6px;
    }
  }
  &.datepicker-orient-bottom {
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }
    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #fff;
    }
  }
}

.datepicker {
  > div {
    display: none;
  }
  &.days .datepicker-days, &.months .datepicker-months, &.years .datepicker-years {
    display: block;
  }
  table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    tr {
      td, th {
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        border: none;
      }
    }
  }
}

.table-striped .datepicker table tr {
  td, th {
    background-color: transparent;
  }
}

.datepicker table tr td {
  &.day {
    &:hover, &.focused {
      background: #eeeeee;
      cursor: pointer;
    }
  }
  &.old, &.new {
    color: #999999;
  }
  &.disabled {
    background: none;
    color: #999999;
    cursor: default;
    &:hover {
      background: none;
      color: #999999;
      cursor: default;
    }
  }
  &.today {
    color: #000000;
    background-color: #ffdb99;
    border-color: #ffb733;
    &:hover {
      color: #000000;
      background-color: #ffdb99;
      border-color: #ffb733;
    }
    &.disabled {
      color: #000000;
      background-color: #ffdb99;
      border-color: #ffb733;
      &:hover {
        color: #000000;
        background-color: #ffdb99;
        border-color: #ffb733;
      }
    }
    &:hover, &.disabled:hover, &:focus, &:hover:focus {
      color: #000000;
      background-color: #ffcd70;
      border-color: #f59e00;
    }
    &.disabled {
      &:focus, &:hover:focus {
        color: #000000;
        background-color: #ffcd70;
        border-color: #f59e00;
      }
    }
    &:active, &:hover:active {
      color: #000000;
      background-color: #ffcd70;
      border-color: #f59e00;
    }
    &.disabled {
      &:active, &:hover:active {
        color: #000000;
        background-color: #ffcd70;
        border-color: #f59e00;
      }
    }
    &.active, &:hover.active {
      color: #000000;
      background-color: #ffcd70;
      border-color: #f59e00;
    }
    &.disabled {
      &.active, &:hover.active {
        color: #000000;
        background-color: #ffcd70;
        border-color: #f59e00;
      }
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.today {
  color: #000000;
  background-color: #ffcd70;
  border-color: #f59e00;
  &:hover {
    color: #000000;
    background-color: #ffcd70;
    border-color: #f59e00;
  }
  &.disabled {
    color: #000000;
    background-color: #ffcd70;
    border-color: #f59e00;
    &:hover {
      color: #000000;
      background-color: #ffcd70;
      border-color: #f59e00;
    }
  }
}

.datepicker table tr td.today {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.today {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.today {
  &.disabled, &:hover.disabled {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  background-color: #ffdb99;
  border-color: #ffb733;
  &:hover {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    background-color: #ffdb99;
    border-color: #ffb733;
    &:hover {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td.today {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &:hover, &.disabled:hover {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
}

.datepicker table tr td.today {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &:focus, &:hover:focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td.today {
  &.disabled:active, &:hover.disabled:active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &:active, &:hover:active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td.today {
  &.disabled.active, &:hover.disabled.active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &.active, &:hover.active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td {
  &.today {
    &:hover:hover {
      color: #000;
    }
    &.active:hover {
      color: #fff;
    }
  }
  &.range {
    background: #eeeeee;
    border-radius: 0;
    &:hover {
      background: #eeeeee;
      border-radius: 0;
    }
    &.disabled {
      background: #eeeeee;
      border-radius: 0;
      &:hover {
        background: #eeeeee;
        border-radius: 0;
      }
    }
    &.today {
      color: #000000;
      background-color: #f7ca77;
      border-color: #f1a417;
      border-radius: 0;
      &:hover {
        color: #000000;
        background-color: #f7ca77;
        border-color: #f1a417;
        border-radius: 0;
      }
      &.disabled {
        color: #000000;
        background-color: #f7ca77;
        border-color: #f1a417;
        border-radius: 0;
        &:hover {
          color: #000000;
          background-color: #f7ca77;
          border-color: #f1a417;
          border-radius: 0;
        }
      }
      &:hover, &.disabled:hover, &:focus, &:hover:focus {
        color: #000000;
        background-color: #f4bb51;
        border-color: #bf800c;
      }
      &.disabled {
        &:focus, &:hover:focus {
          color: #000000;
          background-color: #f4bb51;
          border-color: #bf800c;
        }
      }
      &:active, &:hover:active {
        color: #000000;
        background-color: #f4bb51;
        border-color: #bf800c;
      }
      &.disabled {
        &:active, &:hover:active {
          color: #000000;
          background-color: #f4bb51;
          border-color: #bf800c;
        }
      }
      &.active, &:hover.active {
        color: #000000;
        background-color: #f4bb51;
        border-color: #bf800c;
      }
      &.disabled {
        &.active, &:hover.active {
          color: #000000;
          background-color: #f4bb51;
          border-color: #bf800c;
        }
      }
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.range.today {
  color: #000000;
  background-color: #f4bb51;
  border-color: #bf800c;
  &:hover {
    color: #000000;
    background-color: #f4bb51;
    border-color: #bf800c;
  }
  &.disabled {
    color: #000000;
    background-color: #f4bb51;
    border-color: #bf800c;
    &:hover {
      color: #000000;
      background-color: #f4bb51;
      border-color: #bf800c;
    }
  }
}

.datepicker table tr td.range.today {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.range.today {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled, &:hover.disabled {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  background-color: #f7ca77;
  border-color: #f1a417;
  &:hover {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    background-color: #f7ca77;
    border-color: #f1a417;
    &:hover {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &:hover, &.disabled:hover {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
}

.datepicker table tr td.range.today {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &:focus, &:hover:focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled:active, &:hover.disabled:active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &:active, &:hover:active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled.active, &:hover.disabled.active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &.active, &:hover.active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.selected {
  color: #ffffff;
  background-color: #999999;
  border-color: #555555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #ffffff;
    background-color: #999999;
    border-color: #555555;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  &.disabled {
    color: #ffffff;
    background-color: #999999;
    border-color: #555555;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #999999;
      border-color: #555555;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
  }
  &:hover, &.disabled:hover, &:focus, &:hover:focus {
    color: #ffffff;
    background-color: #858585;
    border-color: #373737;
  }
  &.disabled {
    &:focus, &:hover:focus {
      color: #ffffff;
      background-color: #858585;
      border-color: #373737;
    }
  }
  &:active, &:hover:active {
    color: #ffffff;
    background-color: #858585;
    border-color: #373737;
  }
  &.disabled {
    &:active, &:hover:active {
      color: #ffffff;
      background-color: #858585;
      border-color: #373737;
    }
  }
  &.active, &:hover.active {
    color: #ffffff;
    background-color: #858585;
    border-color: #373737;
  }
  &.disabled {
    &.active, &:hover.active {
      color: #ffffff;
      background-color: #858585;
      border-color: #373737;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.selected {
  color: #ffffff;
  background-color: #858585;
  border-color: #373737;
  &:hover {
    color: #ffffff;
    background-color: #858585;
    border-color: #373737;
  }
  &.disabled {
    color: #ffffff;
    background-color: #858585;
    border-color: #373737;
    &:hover {
      color: #ffffff;
      background-color: #858585;
      border-color: #373737;
    }
  }
}

.datepicker table tr td.selected {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.selected {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled, &:hover.disabled {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #999999;
      border-color: #555555;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  background-color: #999999;
  border-color: #555555;
  &:hover {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    background-color: #999999;
    border-color: #555555;
    &:hover {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #999999;
    border-color: #555555;
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:hover, &.disabled:hover {
    background-color: #999999;
    border-color: #555555;
  }
}

.datepicker table tr td.selected {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #999999;
      border-color: #555555;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:focus, &:hover:focus {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled:active, &:hover.disabled:active {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #999999;
      border-color: #555555;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:active, &:hover:active {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled.active, &:hover.disabled.active {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #999999;
      border-color: #555555;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &.active, &:hover.active {
    background-color: #999999;
    border-color: #555555;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #999999;
      border-color: #555555;
    }
  }
}

.datepicker table tr td.active {
  color: #ffffff;
  background-color: #428bca;
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #ffffff;
    background-color: #428bca;
    border-color: #357ebd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  &.disabled {
    color: #ffffff;
    background-color: #428bca;
    border-color: #357ebd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #428bca;
      border-color: #357ebd;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
  }
  &:hover, &.disabled:hover, &:focus, &:hover:focus {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
  }
  &.disabled {
    &:focus, &:hover:focus {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
  }
  &:active, &:hover:active {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
  }
  &.disabled {
    &:active, &:hover:active {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
  }
  &.active, &:hover.active {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
  }
  &.disabled {
    &.active, &:hover.active {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.active {
  color: #ffffff;
  background-color: #3276b1;
  border-color: #285e8e;
  &:hover {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
  }
  &.disabled {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
    &:hover {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
  }
}

.datepicker table tr td.active {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.active {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.active {
  &.disabled, &:hover.disabled {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  background-color: #428bca;
  border-color: #357ebd;
  &:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    background-color: #428bca;
    border-color: #357ebd;
    &:hover {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td.active {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:hover, &.disabled:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

.datepicker table tr td.active {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:focus, &:hover:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td.active {
  &.disabled:active, &:hover.disabled:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:active, &:hover:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td.active {
  &.disabled.active, &:hover.disabled.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &.active, &:hover.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: #eeeeee;
  }
  &.disabled {
    background: none;
    color: #999999;
    cursor: default;
    &:hover {
      background: none;
      color: #999999;
      cursor: default;
    }
  }
  &.active {
    color: #ffffff;
    background-color: #428bca;
    border-color: #357ebd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #428bca;
      border-color: #357ebd;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    &.disabled {
      color: #ffffff;
      background-color: #428bca;
      border-color: #357ebd;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      &:hover {
        color: #ffffff;
        background-color: #428bca;
        border-color: #357ebd;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }
    }
    &:hover, &.disabled:hover, &:focus, &:hover:focus {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
    &.disabled {
      &:focus, &:hover:focus {
        color: #ffffff;
        background-color: #3276b1;
        border-color: #285e8e;
      }
    }
    &:active, &:hover:active {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
    &.disabled {
      &:active, &:hover:active {
        color: #ffffff;
        background-color: #3276b1;
        border-color: #285e8e;
      }
    }
    &.active, &:hover.active {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
    &.disabled {
      &.active, &:hover.active {
        color: #ffffff;
        background-color: #3276b1;
        border-color: #285e8e;
      }
    }
  }
}

.open .dropdown-toggle.datepicker table tr td span.active {
  color: #ffffff;
  background-color: #3276b1;
  border-color: #285e8e;
  &:hover {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
  }
  &.disabled {
    color: #ffffff;
    background-color: #3276b1;
    border-color: #285e8e;
    &:hover {
      color: #ffffff;
      background-color: #3276b1;
      border-color: #285e8e;
    }
  }
}

.datepicker table tr td span.active {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td span.active {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled, &:hover.disabled {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  background-color: #428bca;
  border-color: #357ebd;
  &:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    background-color: #428bca;
    border-color: #357ebd;
    &:hover {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:hover, &.disabled:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

.datepicker table tr td span.active {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:focus, &:hover:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled:active, &:hover.disabled:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:active, &:hover:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled.active, &:hover.disabled.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &.active, &:hover.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker {
  table tr td span {
    &.old, &.new {
      color: #999999;
    }
  }
  .datepicker-switch {
    width: 145px;
  }
  thead tr:first-child th, tfoot tr th {
    cursor: pointer;
  }
  thead tr:first-child th:hover, tfoot tr th:hover {
    background: #eeeeee;
  }
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
  thead tr:first-child .cw {
    cursor: default;
    background-color: transparent;
  }
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.input-daterange {
  width: 100%;
  input {
    text-align: center;
    &:first-child {
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
  .input-group-addon {
    width: auto;
    min-width: 16px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eeeeee;
    border: solid #cccccc;
    border-width: 1px 0;
    margin-left: -5px;
    margin-right: -5px;
  }
}