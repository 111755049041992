.data-box {
	letter-spacing: ( $theme-letter-spacing / 2 );

	ul,
	li {
		margin: 0;
	}

	&__title {
		text-transform: uppercase;
		@include font-size(12);
		color: $theme-blue;
		font-weight: 500;
	}

	&__icon {
		color: $theme-blue-lighten;
		opacity: 0.3;
		@include font-size(22);
		padding-bottom: ($spacer / 12);
	}
}