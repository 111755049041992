/**
 *	Header addons
 */



.haddons {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	padding: 24px 0 0;
}
