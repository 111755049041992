/**
 *	Content
 */


.content {
	.bg--white {
		background-color: $theme-white;
	}

	&__inner {
		padding: 30px 0;

		// Media queries
		@include media-breakpoint-up(lg) {
			padding: 50px 0;
		}
	}

	&__text {

		p {
			line-height: 1.45em;
		}

		&--highlight {
			@include font-size(24);
			font-weight: 300;
		}
	}
}

.content__background--white {
	padding: ( $spacer / 3 ) ( $spacer / 2 );
	margin-left: -( $spacer / 2 );
	margin-right: -( $spacer / 2 );
	border-radius: 0 3px 3px;

	//Media queries
	@include media-breakpoint-up(lg) {
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
		background-color: $theme-white;
	}

	h1 {
		@include font-size(46);

		// Media queries
		@include media-breakpoint-up(md) {
			margin-bottom: ( $spacer * .75 );
			@include font-size(60);
		}
	}
}
