.nav-main {
	margin: 0 -20px -20px -20px;
	padding: 0;

	&__item {
		list-style: none;
		margin-bottom: 0;
	}

	&__link {
		display: block;
		font-size: 15px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		//color: $theme-blue !important;
		color: #3b3b3b !important;
		padding: 10px 20px 10px 17px;
		border-left: 3px solid transparent;

		&:hover {
			color: $theme-blue;
			border-color: $theme-blue;
		}
	}

	@include media-breakpoint-up(xl) {
		display: flex;
		margin: 0;
		border-top: 1px solid #c2c2c2;

		&__link {
			padding: 12px 0 0 0;
			border-top: 3px solid transparent;
			border-left: 0;

			.nav-main__item:not(:last-child) & {
				margin-right: 20px;
			}

		}

	}
}


.nav-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #c2c2c2;

	&__user {
		margin-right: 20px;
		display: inline-flex;
		align-items: center;
		color: $theme-gray;
		text-decoration: none;

		&:hover {
			color: $theme-gray;
			text-decoration: underline;
		}

		&::before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			margin-right: 8px;
			background-color: $theme-blue;
			border: 2px solid $theme-blue;
			border-radius: 50%;
			background: $theme-blue url('#{$theme-path}/images/icons/user.svg') no-repeat center bottom/14px 14px;
			color: white;
		}
	}

	&__signout {
		img {
			height: 20px;
			width: auto;
		}
	}

	@include media-breakpoint-up(xl) {
		justify-content: flex-end;
		margin-bottom: 12px;
		padding-bottom: 0;
		border: 0;
	}
}


.nav-toggle-checkbox {
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
}

.nav-toggle-button {
	position: relative;
	text-transform: uppercase;
	padding-right: 28px;
	color: $theme-blue;
	font-size: 15px;
	font-weight: 500;

	@include media-breakpoint-up(xl) {
		display: none;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		height: 2px;
		background-color: $theme-blue;
		transition: transform 0.3s, width 0.3s;
	}

	&::before {
		width: 20px;
		transform: translateY(-5px) rotate(0);
	}

	&::after {
		width: 16px;
		transform: translateY(3px) rotate(0);
	}


	.nav-toggle-checkbox:checked ~ & {
		&::before {
			width: 20px;
			transform: translateY(-1px) rotate(-45deg);
		}

		&::after {
			width: 20px;
			transform: translateY(-1px) rotate(45deg);
		}
	}
}


.nav-menu {
	display: none;
	position: absolute;
	z-index: 9999;
	right: 0;
	top: 100%;
	padding: 20px;
	background-color: white;
	box-shadow: -2px 2px 3px rgba($theme-blue, 0.2);

	.nav-toggle-checkbox:checked ~ & {
		display: block;
	}

	@include media-breakpoint-up(xl) {
		display: block;
		position: static;
		padding: 0;
		box-shadow: none;
	}
}