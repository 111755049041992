


.data-grid {

	tr {
		border-bottom: 1px solid #d8d8d8;

		&:hover {
			background-color: #fbfbfb;
			cursor: pointer;
		}
	}

	@include media-breakpoint-up(md) {
		th,
		td {
			padding: 10px;
		}

		tr > :first-child {
			padding-left: 10px;
		}

		& tbody td:first-of-type,
		& tbody td:last-of-type {
			padding: 10px;
		}
	}

	th {
		background-color: #e9e9e9;
		vertical-align: top;
		white-space: nowrap;
	}


	&__sort {
		text-align: right;

		img {
			width: auto;
			height: 6px;
		}
	}

	.form-group {
		margin-left: 0;
	}
}





.data-grid-menu {
	position: absolute;
	background-color: white;
	border: 1px solid #e5e5e5;
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.20);
	display: flex;
	flex-direction: column;

	&__link {
		display: flex;
		align-items: center;
		padding: 12px 20px;
		font-size: 13px;
		font-weight: 500;
		text-decoration: none;
		color: $theme-gray-dark;
		border-left: 2px solid transparent;

		&:not(.disabled):hover{
			color: $theme-blue;
			background-color:#f0f0f0;
			border-color: $theme-gray-dark;
			cursor: pointer;
		}
	}

	&__icon {
		width: 16px;
		height: 16px;
		margin-right: 15px;
	}
}