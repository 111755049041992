.popup {
	display: none;

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.85);
		// background-color: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: opacity 0.3s;
	}


	&__window {
		position: absolute;
		top: 50vh;
		left: 50vw;
		width: 90%;
		max-width: 650px;
		padding: 20px;
		background-color: white;
		box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.20);
		border: 1px solid #e5e5e5;
		opacity: 0;
		transform: translate(-50%, -60%);
		transition: opacity 0.5s, transform 0.5s;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 0;
		border: 0;
		outline: 0 !important;
		background: transparent;
		cursor: pointer;

		img {
			width: 16px;
			height: 16px;
		}
	}

	&__header {
		display: flex;
		justify-content: center;
		align-items: center;
		color: $theme-gray-dark;
		font-size: 14px;
		letter-spacing: normal;
		margin-top: 10px;
		margin-bottom: 20px;
	}

	&__header-icon {
		width: auto;
		height: 1em;
		margin-right: 0.7em;
	}

	&__body {
		letter-spacing: normal;
	}


	&--open {
		display: block;

		.popup__background {
			opacity: 1;
		}

		.popup__window {
			opacity: 1;
			transform: translate(-50%, -50%);
		}
	}
}