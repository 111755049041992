/**
 *	Buttons
 */


.btn {
	cursor: pointer;
	@include font-size(12);
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: .5px;

	&[disabled],
	&.disabled,
	&:disabled {
		cursor: not-allowed;
		opacity: .38;

		&.btn-inverse {
			color: $white;
		}
	}

	&.btn--raw {
		color: $theme-gray-dark;
		padding: 0;
		border: none;

		&:hover,
		&:focus {
			&:not([disabled]) {
				color: $theme-yellow;
			}
		}

		&:active {
			&:not([disabled]) {
				color: darken($theme-yellow, 8%);
			}
		}

		&.btn-inverse {
			color: $white;
		}
	}

	&.btn-sm {
		@include font-size(10);
		line-height: 1.45;

		&.btn--more,
		&.btn--external,
		&.btn--direct {
			&::after {
				@include font-size(8);
			}
		}

		&.btn--more,
		&.btn--external {
			padding-right: ( $spacer / 4 );
		}

		&.btn--direct {
			padding-left: ( $spacer / 4 );
		}
	}

	&.btn-lg {
		@include font-size(14);
		line-height: 1.45;

		&.btn--more,
		&.btn--external,
		&.btn--direct {
			&::after {
				@include font-size(12);
				top: ( $spacer / 12 );
			}
		}

		&.btn--more {
			padding-right: ( $spacer / 2.5 );
		}

		&.btn--external {
			padding-right: ( $spacer / 3 );
		}

		&.btn--direct {
			padding-left: ( $spacer / 2 );
		}
	}

	&.btn--more,
	&.btn--external,
	&.btn--direct {
		position: relative;

		&::after {
			@include icon-mix();
			@include font-size(10);
			color: $theme-yellow;
			position: absolute;
			top: ( $spacer / 15 );
		}
	}

	&.btn--more,
	&.btn--external {
		padding-right: ( $spacer / 3 );

		&::after {
			right: 0;
		}
	}

	&.btn--more {
		&::after {
			content: '\e912';
		}
	}

	&.btn--external {
		vertical-align: initial;
		text-decoration: underline;
		text-transform: none;
		letter-spacing: $theme-letter-spacing;

		&::after {
			content: '\e909';
		}
	}

	&.btn--direct {
		padding-left: ( $spacer / 2.5 );

		&::after {
			content: '\e906';
			left: 0;
		}
	}

	&.btn-primary {
		border-color: darken($theme-blue-light, 5%);
		background-color: $theme-blue-light;
		color: $white;

		&:hover,
		&:focus {
			&:not([disabled]) {
				border-color: darken($theme-blue-light, 5%);
				background-color: darken($theme-blue-light, 5%);
			}
		}

		&:active {
			&:not([disabled]) {
				border-color: darken($theme-blue-light, 8%);
				background-color: darken($theme-blue-light, 8%);
				color: $theme-white;
			}
		}

		&.btn-inverse {
			color: $theme-gray-dark;

			&:active {
				color: $theme-gray-dark;
			}
		}
	}

	&.btn-outline-primary {
		border-color: $theme-blue-light;
		color: $white;
		text-align: left;
		white-space: normal;
		font-weight: 500;

		&:hover,
		&:focus {
			&:not([disabled]) {
				border-color: $theme-blue-light;
				background-color: $theme-blue-light;
			}
		}

		&:active {
			&:not([disabled]) {
				border-color: darken($theme-blue-light, 8%);
				background-color: darken($theme-blue-light, 8%);
				color: $theme-white;
			}
		}

		&.btn-inverse {
			color: $white;

			&:not([disabled]) {
				&:active,
				&:hover,
				&:focus {
					color: $theme-gray-dark;
				}
			}
		}
	}

	&.btn-secondary {
		border-color: transparent;
		background-color: transparent;
		color: $theme-gray-dark;

		&:hover,
		&:focus {
			&:not([disabled]) {
				border-color: transparent;
				background-color: rgba($theme-gray-dark, .1);
			}
		}

		&:active {
			&:not([disabled]) {
				border-color: transparent;
				background-color: rgba($theme-gray-dark, .2);
				color: $theme-gray-dark;
			}
		}

		&.btn-inverse {
			color: $white;

			&:hover,
			&:focus {
				&:not([disabled]) {
					border-color: transparent;
					background-color: rgba($white, .1);
				}
			}

			&:active {
				&:not([disabled]) {
					border-color: transparent;
					background-color: rgba($white, .2);
					color: $white;
				}
			}
		}
	}

	&.btn--color-white {
		color: $theme-white;
	}

	&.btn__content-link {
		&,
		&:visited {
			display: inline;
			padding: 0;
			color: $theme-gray-dark;
			@include font-size(14);
			font-weight: 400;
			letter-spacing: $theme-letter-spacing;
			white-space: normal;
			text-transform: none;
			text-decoration: underline;
		}

		&:hover,
		&:focus,
		&:active {
			color: $theme-yellow;
		}
	}
}