/*
fullscreen - kompletni prehled
 */
.fullscreen-container {
  max-width: none !important;
}

.fullscreen-content {
  padding-top: 0 !important;

  .paginator__list {
    margin-bottom: 0 !important;
  }

  .table-sticky-wrap {
    height: calc(100vh - 160px);
    overflow: scroll;
  }
}

.table-sticky-wrap {
  position: relative;
  overflow-x: scroll;

  table {
    position: relative;
    //border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 0;

  }


  td {
    padding: 3px 10px !important;
    color: black;
    letter-spacing: 0;
    height: 46px;
  }

  td, th {
    //border: none!important;
    /*!*border: 1px solid grey;*!*/
    white-space: nowrap;
    /*border-top-width: 0px;*/
    padding: 0.25em;
  }


  thead {

    th {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
      z-index: 50;
      border: none !important;
    }

    th:first-child, th.sticky-column {
      left: 0;
      z-index: 60 !important;
    }

    .form-group {
      margin-bottom: 0 !important;

      input, select {
        padding: 5px 10px;
        font-size: 12px;
      }

      label {
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: 1rem;
        white-space: initial;
      }

      .select--primary::after {
        top: 8px;
        right: 5px;
      }
    }
  }

  .sticky-column {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 2;
    min-width: 80px;

    &.sc0 {
      left: 80px;
      z-index: 7;
    }

    &.sc1 {
      left: 180px;
      z-index: 8;
    }

    &.sc2 {
      left: 240em;
      z-index: 9;
    }
  }

}

.tooltipheaderdots:after {
  content: "...";
  position: absolute;
  //right: 5px;
  right: 50%;
  bottom: 0px;
}

//.color_cell_undefined {
//  border: 1px solid #f5f5f5 !important;
//}

$cl1: #2362a2;
$cl2: grey;
$cl3: white;
$cl4: black;
$opacity: 0.05;

.data-grid tr {
  .color_cell_blue {
    background: rgba($cl1, $opacity);
  }

  .color_cell_grey {
    background: rgba($cl2, $opacity);
  }

  .color_cell_light {
    background: rgba($cl3, $opacity);
  }

  .color_cell_dark {
    background: rgba($cl4, $opacity);
  }
}

.tablebtns {
  position: relative;
  display: flex;
  flex-direction: row;
}

.data-grid thead, .tablebtns {
  .color_cell_blue {
    background: #e0e6ed;
  }

  .color_cell_grey {

  }

  .color_cell_light {
    background: #fafafa;
  }

  .color_cell_dark {
  }
}


.size-column-small {

  min-width: 50px !important;
  max-width: 50px !important;
  overflow: hidden;
}

.size-column-medium {
  min-width: 170px !important;
  max-width: 170px !important;
  overflow: hidden;
}

.size-column-big {
  min-width: 350px !important;
  max-width: 350px !important;
  overflow: hidden;

}

.insidetootlitpcell, .insidetootlitpcell * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.hightlightcol {
  background: #f8f6e9 !important;
}

.firstHightLine *{
  color: red;
}