

.form-nav {
	//max-height: calc(100vh - 100px);
	//overflow-y: auto;


	&__menu {
		margin: 0;
		padding: 0;

		li {
			padding: 20px 50px 20px 0;
			text-align: right;
			position: relative;
			line-height: 20px;
			margin: 0;
		}
	}

	&__title::after,
	&__item::after,
	&__end::after {
		content: '';
		position: absolute;
		right: 7px;
		top: 20px;
		width: 20px;
		height: 20px;
		background-color: #999;
		border-radius: 50%;
		box-shadow: 4px 4px 3px #99999950;
	}

	&__title::before,
	&__item::before,
	&__end::before {
		content: '';
		position: absolute;
		right: 17px;
		top: 0;
		bottom: 0;
		width: 1px;
		background-color: #999;
		border-radius: 0;
	}

	&__title {
		padding-top: 0 !important;
		@include font-size(20);
		color: black;

		&::after {
			width: 13px;
			height: 13px;
			top: 4px;
			right: 11px;
			background-image: url('#{$theme-path}/images/icons/arrow-up.svg');
			background-size: 8px 9px;
			background-position: 2px 2px;
			background-repeat: no-repeat;
		}

		&::before {
			top: 50%;
		}
	}

	&__end {
		@include font-size(20);
		color: black;

		&::after {
			width: 13px;
			height: 13px;
			top: 24px;
			right: 11px;
			background-image: url('#{$theme-path}/images/icons/arrow-down.svg');
			background-size: 8px 9px;
			background-position: 2px 2px;
			background-repeat: no-repeat;
		}

		&::before {
			bottom: 10px;
		}
	}

	&__item--valid {
		&::after {
			background-color: #009B49;
			background-image: url('#{$theme-path}/images/icons/check.svg');
			background-size: 12px 12px;
			background-position: 4px 4px;
			background-repeat: no-repeat;
		}
	}

	&__item--active {
		padding-top: 40px !important;
		padding-bottom: 40px !important;

		&::after {
			width: 35px;
			height: 35px;
			right: 0;
			top: 33px;
			background-color: $theme-blue;
			background-image: url('#{$theme-path}/images/icons/chevron-double-right.svg');
			background-size: 15px 15px;
			background-position: 10px 10px;
			background-repeat: no-repeat;
		}

		.form-nav__link {
			color: $theme-blue !important;
			font-weight: 500;
		}
	}


	&__link {
		@include font-size(14);
		color: black !important;
		text-decoration: none;
		text-transform: uppercase;
	}


	&__item:hover {
		.form-nav__link {
			color: $theme_blue !important;
		}

		&::after {
			background-color: $theme_blue;
		}
	}




}


