/**
 *	Footer
 */

 .footer-logo-wrapper {
	 text-align: center;
 }

.footer-logo {
	height: 55px;
	width: auto;
}

.page-footer {
	color: $footer-text-color;
	font-weight: normal;
	text-align: center;

	a {
		color: $footer-text-color;
		text-decoration: underline;

		&:hover,
		&:focus,
		&:active {
			color: $theme-blue;
		}

		&::before {
			display: none !important;
		}
	}

	p {
		font-size: 13px;
		margin: 0;
	}

	&__bottom {
		padding: 1em 0;
		border-top: 1px solid #a3a3a3;
		color: $footer-text-color;
		background-color: $footer-bg;
	}
}










.footer {
	background-color: $footer-bg;
	color: $footer-text-color;

	ul {
		@include reset-pa-ma();
		@include after-clear();
		list-style: none;

		&.bigger {

			a {
				&,
				&:visited {
					@include font-size(20);
					font-weight: 400;
				}
			}

			li {
				line-height: 1.3;
				padding-bottom: 1.2em;

				// Media queries
				@include media-breakpoint-up(sm) {
					padding-bottom: .8em;
				}
			}
		}

		li {
			@include reset-pa-ma();
			padding-bottom: 1.2em;

			// Media queries
			@include media-breakpoint-up(sm) {
				padding-bottom: .4em;
			}

			&:last-child {
				padding-bottom: 0;
			}

			&::before {
				display: none;
			}
		}
	}

	h3 {
		color: $theme-white;
	}

	a {
		&,
		&:visited {
			color: $theme-white;
			text-transform: uppercase;
			@include font-size(12);
			font-weight: 500;
			text-decoration: none;
		}

		&:hover,
		&:focus {
			color: $theme-yellow;
			text-decoration: underline;
		}

		&:active {
			color: $theme-yellow;
			text-decoration: none;
		}

		@include complement-visited-links() {
			color: $theme-white;
		}
	}

	&__links {
		padding: ( $spacer / 1.5 ) 0;
	}

	&__common {
		padding: ( $spacer / 1.5 ) 0;
		@include font-size(12);
		font-weight: 500;
		border-top: 1px solid rgba($theme-white, .2);

		// Media queries
		@include media-breakpoint-up(lg) {
			padding: $spacer 0;
		}
	}

	&__brand {
		@include reset-pa-ma();
		@include font-size(12);
		text-transform: uppercase;
		font-weight: 500;
		line-height: 1.34;

		// Media queries
		@include media-breakpoint-up(sm) {
			line-height: 1.85;
		}
	}
}