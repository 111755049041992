/**
* Date picker
*/

.date-picker {
	legend {
		display: none;
	}

	.form-control--date-picker {
		@include placeholder(rgba(59, 59, 59, 0.35));
	}

	&--icon {
		position: absolute;
		top: 10px;
		right: 10px;
		color: $theme-blue-light;
		@include font-size(18);
		cursor: pointer;
		pointer-events: none;
		z-index: 9;
	}

	.help-block {
		color: $theme-gray-dark;
	}
}

.datepicker-dropdown {
	background-color: $theme-blue;
	color: $white;

	&::before,
	&.datepicker-orient-bottom::after {
		border-top-color: $theme-blue;
	}

	thead {
		display: table-header-group;

		th,
		th:first-child {
			color: $white;
			padding: (  $spacer / 6 ) (  $spacer / 7 );

			// Media queries
			@include media-breakpoint-up(xl) {
				padding: (  $spacer / 6 ) ( $spacer / 5 );
			}
		}

		tr:first-child {
			th {
				&:hover,
				&:focus {
					background-color: rgba($white, .1);
				}
			}
		}
	}

	tbody {
		display: table-row-group;

		tr {
			display: table-row;
			border-top: none;

			td {
				&.today {
					background-color: transparent;
					border-color: transparent;
					color: $theme-yellow;
				}

				&.day,
				&.year,
				span {
					&:hover,
					&:focus {
						background-color: rgba($white, .1);
					}
				}
			}
		}

		td {
			display: table-cell;
			@include font-size(12);
			padding: ( $spacer / 5 );

			&:first-of-type {
				padding: 0;

				// Media queries
				@include media-breakpoint-up(md) {
					padding: ( $spacer / 5 );
				}
			}
		}
	}
}