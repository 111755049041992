/**
 *	Header
 */

.top-header {
	position: relative;
}





.header-logo {
	height: 70px;
	width: auto;
}

.header-logo-medium {
	height: 40px;
	width: auto;

	@include media-breakpoint-up(xl) {
		height: 55px;
	}
}

.header-logo-small {
	height: 40px;
	width: auto;
}



.header {
	background-color: $header-bg;
	color: $header-color;
	padding-bottom: $guide-negative-offset-sp-m;

	// Media queries
	@include media-breakpoint-up(lg) {
		padding-bottom: $guide-negative-offset-sp-d;
	}

	&__inner {
		padding-top: ( $grid-gutter-width * .4 );

		// Media queries
		// @include media-breakpoint-up(sm) {
		//     padding: $grid-gutter-width 0;
		// }
		@include media-breakpoint-up(md) {
			padding: ( $grid-gutter-width * .4 ) 0;
		}
	}

	span {
		@include reset-pa-ma();
	}

	// Infoline number
	.infoline {
		margin-left: -( $grid-gutter-width * .44 );
		@include font-size(15);
		font-weight: 300;

		// Media queries
		@include media-breakpoint-up(sm) {
			margin-left: 0;
		}

		a[href^='tel'] {
			&,
			&:visited {
				color: $theme-white;
			}
		}
	}

	.info-number {
		display: inline-block;
		font-weight: 500;
	}
}

.header__toolbox {
	padding-bottom: ($grid-gutter-width * .25);

	// Media queries
	@include media-breakpoint-up(md) {
		border-bottom: 1px solid rgba(255, 255, 255, .4);
	}

	// Login button
	.btn-login {
		&,
		&:visited {
			display: inline-block;
			color: $theme-white;
			@include font-size(24);
			font-weight: 500;
			text-transform: uppercase;
			text-decoration: none;
		}

		&:focus,
		&:hover {
			color: $theme-yellow;
			text-decoration: none;
		}

		&:active {
			color: $theme-yellow;
			text-decoration: none;
		}
	}

	.pvs-theme-icon-person {

		// Media queries
		@include media-breakpoint-up(sm) {
			display: none;
		}
	}

	.login-text {
		display: none;
		@include font-size(12);
		font-weight: bold;

		// Media queries
		@include media-breakpoint-up(sm) {
			display: block;
		}
	}

	// Languages change
	.lang-change__link {
		&,
		&:visited {
			display: inline-block;
			width: 24px;
			height: 12px;
			margin-left: ( $grid-gutter-width * .2 );
			background-image: url('#{$theme-path}/images/layout/british-flag.svg?v=2');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 24px auto;
			text-align: left;
			line-height: 1.6em;
			text-indent: -9999px;

			// Media queries
			@include media-breakpoint-up(sm) {
				line-height: 1em;
			}
		}

		&:focus,
		&:hover {
			text-decoration: none;
		}

		&:active {
			text-decoration: none;
		}
	}
}

.header--title {
	margin-top: ( $grid-gutter-width * 1 );
	text-align: center;

	h2 {
		color: $theme-white;
		@include font-size(46);
		font-weight: 100;

		// Media queries
		@include media-breakpoint-up(xl) {
			@include font-size(60);
		}
	}
}

.header__logo {
	&,
	&:visited {
		display: block;
		width: 40px;
		height: 50px;
		background-image: url('#{$theme-path}/images/layout/pvs-logo-mobile.svg?v=2');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 40px auto;
		color: $theme-white;
		text-indent: -9999px;

		// Media queries
		@include media-breakpoint-up(md) {
			width: 80px;
			height: 102px;
			background-size: 80px auto;
		}
		@include media-breakpoint-up(lg) {
			width: 188px;
			background-image: url('#{$theme-path}/images/layout/pvs-logo.svg');
			background-size: 188px auto;
		}
	}

	&:focus,
	&:hover {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}

	&--lp {
		&,
		&:visited {
			height: 25px;
			background-image: url('#{$theme-path}/images/layout/e-obcanka-logo.svg');
			background-size: 40px auto;

			// Media queries
			@include media-breakpoint-up(md) {
				height: 102px;
				background-size: 80px auto;
			}
			@include media-breakpoint-up(lg) {
				width: 212px;
				background-image: url('#{$theme-path}/images/layout/e-obcanka.svg');
				background-size: 212px auto;
			}
		}
	}
}

.header__logo--print {
	display: none;
}

.header__search-nav {

	// Media queries
	@include media-breakpoint-up(md) {
		margin-top: -( $grid-gutter-width * 1.1 );
	}

	&--lp {

		// Media queries
		@include media-breakpoint-up(md) {
			margin-top: -68px;
		}

		.navigation .nav--primary {

			// Media queries
			@include media-breakpoint-up(sm) {
				margin-bottom: ( $grid-gutter-width / 4 );
			}
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}

			.nav__link,
			.nav__link:visited {
				white-space: nowrap;

				// Media queries
				@include media-breakpoint-up(sm) {
					padding-top: ( $grid-gutter-width * .2 );
					padding-bottom: ( $grid-gutter-width * .2 );
				}
				@include media-breakpoint-up(md) {
					padding-top: ( $grid-gutter-width * .3 );
					padding-bottom: ( $grid-gutter-width * .15 );
				}
				@include media-breakpoint-up(xl) {
					padding-top: ( $grid-gutter-width * .6 );
					padding-bottom: 0;
				}
			}

			.nav__item {
				// Media queries
				@include media-breakpoint-up(sm) {
					margin-right: ( $grid-gutter-width / 2 );
				}
				@include media-breakpoint-up(xl) {
					margin-right: auto;
				}
			}
		}
	}
}

.header--hp {
	padding-bottom: $guide-negative-offset-hp-m;

	// Media queries
	@include media-breakpoint-up(md) {
		height: 652px;
		background-image: url('#{$theme-path}/images/layout/hp-header.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	@include media-breakpoint-up(lg) {
		padding-bottom: $guide-negative-offset-hp-d;
	}
	@include media-breakpoint-up(xl) {
		background-image: url('#{$theme-path}/images/layout/hp-header-xl.jpg');
		background-repeat: no-repeat;
		background-position: center;
	}

	.header__logo {

		// Media queries
		@include media-breakpoint-up(md) {
			width: 168px;
			height: 86px;
			margin-top: ( $grid-gutter-width * .4 );
			background-image: url('#{$theme-path}/images/layout/pvs-logo.svg');
			background-size: 168px auto;
		}
	}

	.header__toolbox {
		border: none;

		// Media queries
		@include media-breakpoint-up(md) {
			padding-bottom: ( $grid-gutter-width * .275 );
			border-bottom: 1px solid rgba(255, 255, 255, .4);
		}
	}

	.infoline {

		// Media queries
		@include media-breakpoint-up(md) {
			padding-bottom: ( $grid-gutter-width * .25 );
			margin-right: -$grid-gutter-width;
			border-bottom: 1px solid rgba(255, 255, 255, .4);
		}
		@include media-breakpoint-up(lg) {
			padding-bottom: 0;
			margin-top: ( $grid-gutter-width * .6 );
			margin-left: 13%;
			margin-right: 0;
			text-transform: uppercase;
			font-weight: 400;
			border: none
		}

		br {
			display: none;

			// Media queries
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}

	.info-number {

		// Media queries
		@include media-breakpoint-up(lg) {
			@include font-size(25);
			line-height: 1.6em;
		}
	}

	.search__input {

		// Media queries
		@include media-breakpoint-up(md) {
			padding: ( $grid-gutter-width * .37 ) ( $grid-gutter-width * .35 );
			@include font-size(24);

			&:focus {
				padding-bottom: ( $grid-gutter-width * .37 );
			}
		}
	}

	.search__button {

		// Media queries
		@include media-breakpoint-up(md) {
			right: 10px;
			height: 56px;
			padding: ( $grid-gutter-width * .25 ) ( $grid-gutter-width * .55 );
		}

		&--icon {

			// Media queries
			@include media-breakpoint-up(md) {
				display: none;
			}
		}

		&--text {

			// Media queries
			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}
}

.header--message {

	// Media queries
	@include media-breakpoint-up(md) {
		height: 915px;
	}
	@include media-breakpoint-up(lg) {
		height: 865px;
	}
}