/**
 *	Theme variables
 *
 *	This file defines new variables and extends standards variables from Bootstrap.
 */


// Colors
$white:                       #fff;                              // Pure white color
$theme-white:                 $white;                              // Pure white color
$black:                       #000;                              // Pure black color
$theme-black:                 $black;                              // Pure black color

$theme-blue-lighten:          #3375ae;
$theme-blue-light:            #337dbd;
$theme-blue:                  #2362a2;
$theme-blue-dark:             #254e80;

$theme-yellow:                #ecae1a;
$theme-red:                   #e04857;
$theme-gray-dark:             #3b3b3b;
$theme-gray:                  #707070;
$theme-gray-lighten:          #e1e1e1;
$theme-gray-light:            #f5f5f5;


$primary:											$theme-blue !default;


// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px
);



// Layout
$border-radius:               3px !default;
$enable-shadows:              true !default;

$theme-bg:                    $theme-gray-light;
$theme-font-size-base-raw:    14;
$theme-font-size-base:        14px !default;

$theme-class-def:             'pvs-theme';
$theme-class:                 '.#{$theme-class-def}';
$theme-path:                  '';
$theme-text-color:            $theme-gray;
$theme-letter-spacing:        .075em;
$theme-headings-color:        $theme-red;

$body-wrapper:                '.body__wrapper';

$font-family-sans-serif:      'Roboto', helvetica, arial, sans-serif !default;

$font-weight-base:            400 !default;
$headings-line-height:        1.3 !default;
$line-height-base:            ( 20 / 15 ) !default;

$grid-gutter-width:           20px !default;
$spacer:                      $grid-gutter-width !default;

$main-bg:                     $theme-gray-light;

$header-bg:                   $theme-blue-dark;
$header-color:                $theme-white;

$footer-bg:                   white;
$footer-text-color:           #6b6b6b;

$micro-animation-time:        150ms;

$link-color:                  $theme-blue !default;
$link-decoration:             underline;
$link-hover-color:            $theme-yellow !default;
$link-hover-decoration:       none;
$link-transition:             color .15s ease-in-out, text-decoration .15s ease-in-out;


$box-border-color:            rgba(#464646, .2);
$box-border-color-white:      rgba($theme-white, .3);

$input-btn-padding-y:         1.1rem !default;
$input-btn-padding-x:         1.1rem !default;

$input-btn-padding-y-sm:      .7rem !default;
$input-btn-padding-x-sm:      1.3rem !default;

$input-btn-padding-y-lg:      1.4rem !default;
$input-btn-padding-x-lg:      2.5rem !default;

$input-bg:                    $white !default;
$input-border-radius:         3px 3px 0 0 !default;
$input-box-shadow:            none !default;
$input-focus-border-color:    $theme-blue-light !default;
$input-focus-color:           $theme-gray-dark !default;

$btn-font-weight:             500 !default;
$btn-box-shadow:              none !default;
$btn-active-box-shadow:       none !default;

$btn-link-disabled-color:     $theme-gray-dark !default;

$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius !default;
$btn-border-radius-sm:        $border-radius !default;

$guide-shadow:                0 1px 4px 0 rgba(0, 0, 0, .41);
$guide-negative-offset-hp-m:  70px;
$guide-negative-offset-sp-m:  60px;
$guide-negative-offset-hp-d:  80px;
$guide-negative-offset-sp-d:  60px;

$cursor-disabled:             not-allowed !default;

$h1-font-size:                64px;
$h1-font-size-mobile:         44px;
$h2-font-size:                22px;
$h2-font-size-mobile:         20px;
$h3-font-size:                18px;
$h4-font-size:                12px;
$h5-font-size:                12px;
$h6-font-size:                12px;

$headings-font-weight:        500 !default;
$headings-color:              $theme-red !default;

$custom-control-gutter:                 4rem !default;

$custom-control-indicator-size:         2rem !default;
$custom-control-indicator-bg:           transparent !default;
$custom-control-indicator-bg-size:      80% 80% !default;
$custom-control-indicator-box-shadow:   none !default;

$custom-control-indicator-checked-color:        $theme-blue-light !default;
$custom-control-indicator-checked-bg:           transparent !default;
$custom-control-indicator-checked-box-shadow:   none !default;
//$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$white}' d='M21.148,3.107l-13.083,13.083l-5.213,-5.213l-2.352,2.351l7.565,7.565l15.435,-15.435l-2.352,-2.351Z'/%3E%3C/svg%3E") !default;

//$custom-radio-indicator-icon-checked:           url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$white}'/%3E%3C/svg%3E") !default;

$custom-radio-indicator-icon-checked-invalid:   url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$theme-red}'/%3E%3C/svg%3E") !default;

$custom-control-indicator-focus-box-shadow:     none !default;

$custom-control-indicator-active-color:         $white !default;
$custom-control-indicator-active-bg:            $theme-blue-light !default;
$custom-control-indicator-active-box-shadow:    none !default;

$custom-checkbox-indicator-border-radius:       $border-radius !default;

// Toggle
$toggle-size-x:                                 4rem;
$toggle-size-y:                                 2.4rem;
$toggle-border-width:                           .1rem;
$toggle-pointer-size:                           $toggle-size-y - $toggle-border-width - $toggle-border-width;
$toggle-pointer-shadow:                         0 2px 4px 0 rgba(0, 0, 0, .5);


// Breadcrumbs
$breadcrumb-bg:                                 transparent !default;
$breadcrumb-padding-y:                          0 !default;
$breadcrumb-padding-x:                          0 !default;
$breadcrumb-margin-bottom:                      0 !default;
$breadcrumb-divider:                            '\e912';
$breadcrumb-divider-color:                      $theme-gray-dark;

// Tabs
$tab-list-border-color:                         rgba($theme-gray, .3);
$tab-link-color:                                $theme-blue;
$tab-link-active-color:                         $theme-gray-dark;
$tab-link-hover-color:                          $theme-yellow;