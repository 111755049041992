/**
 *	Layout
 */


.body__wrapper-in {
	position: relative;
	// overflow: hidden;

	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		.container-center {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}
