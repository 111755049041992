/**
 *	Messages
 */


.breadcrumb {
	flex-wrap: nowrap;
	margin-left: 0;
	border-radius: 0;
	@include font-size(12);
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: .5px;

	& > .breadcrumb__item {
		display: none;
		color: $theme-blue;
		@include reset-pa-ma();
		@include text-truncate;
		padding-top: ( $spacer / 12 );
		padding-bottom: ( $spacer / 12 );

		// Media queries
		@include media-breakpoint-up(md) {
			padding-top: 0;
			padding-bottom: 0;
			display: list-item;
		}

		&--active {
			color: $theme-gray-dark;
		}

		&::before {
			display: none;
		}

		&:nth-child(n+2) {
			&::before {
				color: $theme-gray-dark;
				display: inline;
				position: relative;
				top: auto;
				left: auto;
				content: $breadcrumb-divider;
				@include icon-mix();
				@include font-size(10);
				opacity: .5;
				padding: 0 ( $spacer / 6 );
			}
		}

		&:nth-last-child(1) {
			overflow: visible;
			text-overflow: clip;
		}

		&:nth-child(1) {
			// Media queries
			@include media-breakpoint-up(md) {
				overflow: visible;
				text-overflow: clip;
			}
		}

		&:nth-last-child(1),
		&:nth-last-child(2) {
			display: list-item;
		}
	}

	a.breadcrumb__link {
		&,
		&:visited {
			text-decoration: none;
		}

		&:focus,
		&:hover {
			color: $theme-blue;
			text-decoration: underline;
		}

		&:active {
			text-decoration: underline;
		}
	}

	&--inverse {
		& > .breadcrumb__item {
			color: $white;

			&:nth-child(n+2) {
				&::before {
					color: $white;
				}
			}
		}

		a.breadcrumb__link {
			&,
			&:visited {
				color: $white;
			}

			&:focus,
			&:hover {
				color: $theme-yellow;
			}
		}
	}
}